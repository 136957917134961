::selection {
  background: #6B705C; /* WebKit/Blink Browsers */
  color:white;
}
::-moz-selection {
  background: #6B705C; /* Gecko Browsers */
  color:white;
}
html,body {
  max-width: 100%;
  background-color:#D3BB8F;
}
.App {
  text-align: center;
  height:900px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* menu bar style */
#menu-bar {
  height:85px;
  /*background:#D3BB8F;*/
  background:#6B705C;
  padding-top:25px;
  color:black;
  box-shadow:2px 2px 4px #000000;
}
/* sticky class is added to header when scrolled */
.sticky {
  position:fixed;
  top: 0;
  width: 100%
}
#menu-items {
  position:absolute;
  text-align:center;
  width:100%;
}
#menu-items-small {
  position:absolute;
}
#menu-item {
  /* position:relative; */
  /* left:55%; */
  list-style-type:none;
  text-decoration:none;
  margin:0;
  /* float:left; */
  color:black;
  text-align:center;
  padding:38px 14px 37px 14px;
  text-decoration:none;
  font-size:17px;
}
#menu-item-active {
  font-weight:800;
  position:relative;
  left:55%;
  list-style-type:none;
  margin:0;
  float:left;
  color:black;
  text-align:center;
  padding:38px 14px 37px 14px;
  text-decoration:none;
  font-size:17px;
}
#menu-item-medium {
  position:relative;
  left:35%;
  list-style-type:none;
  margin:0;
  float:left;
  color:black;
  text-align:center;
  padding:38px 14px 37px 14px;
  text-decoration:none;
  font-size:17px;
}
#menu-item-medium-active {
  font-weight:800;
  position:relative;
  left:35%;
  list-style-type:none;
  margin:0;
  float:left;
  color:black;
  text-align:center;
  padding:38px 14px 37px 14px;
  text-decoration:none;
  font-size:17px;
}
#menu-item-small {
  position:relative;
  left:9%;
  list-style-type:none;
  margin:0;
  float:left;
  color:black;
  text-align:center;
  padding:38px 14px 37px 14px;
  text-decoration:none;
  font-size:17px;
}
#menu-item-small-active {
  font-weight:800;
  position:relative;
  left:9%;
  list-style-type:none;
  margin:0;
  float:left;
  color:black;
  text-align:center;
  padding:38px 14px 37px 14px;
  text-decoration:none;
  font-size:17px;
}
#menu-item-xs {
  padding:5px;
  cursor:pointer;
  /* margin:15px; */
  text-decoration:none;
  color:black;
}
#menu-item-xs-active {
  font-weight:800;
  position:relative;
  left:35%;
  list-style-type:none;
  margin:0;
  float:left;
  color:black;
  text-align:center;
  padding:38px 14px 37px 14px;
  text-decoration:none;
  font-size:17px;
}
#menu-item:hover {
  background-color:#D3BB8F;
  transition: 2s;
}
#menu-item-medium:hover {
  background-color:#D3BB8F;
  transition: 2s;
}
#menu-item-small:hover {
  background-color:#D3BB8F;
  transition: 2s;
}
#menu-item-xs:hover {
  background-color:#D3BB8F;
  transition: 2s;
}
#footer {
  background-color:#6B705C;
  height:115px;
}
#footer-sm {
  background-color:#6B705C;
  height:150px;
}
.social {
  padding:'50px';
  width:'50px';
}
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}
.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  padding:7px;
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}
/* primary buttons on website */
#btn-primary {
  font-weight:300;
  /*background:#6B705C;*/
  background: #737854;
  color: black;
  border:thin black;
  padding:7px;
}
#btn-primary:hover {
  background: #333333;
  color:white;
}
/* container holding dropdown menu */
#dropdown-container {
  position: relative;
  float:right;
  padding-right:25px;
}
/* button to toggle dropdown */
#dropdown-btn {
  /* width: 50px; */
  padding-left:15px;
  padding-right:15px;
  border:none;
  background-color:#D3BB8F;
  color:black;
  cursor: pointer;
  font-size: 25px;
}

@media screen and (max-width: 340px) {
  #dropdown {
    position: absolute;
    top: 100%;
    left: -260%;
    width: 150px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
  }
}
@media screen and (min-width: 341px) and (max-width: 359px) {
  #dropdown {
    position: absolute;
    top: 100%;
    left: -395%;
    width: 150px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
  }
}
@media screen and (min-width: 360px) and (max-width: 370px) {
  #dropdown {
    position: absolute;
    top: 100%;
    left: -360%;
    width: 150px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
  }
}
@media screen and (min-width: 371px) and (max-width: 400px) {
  #dropdown {
    position: absolute;
    top: 100%;
    left: -395%;
    width: 150px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
  }
}
@media screen and (min-width: 401px) and (max-width: 500px) {
  #dropdown {
    position: absolute;
    top: 100%;
    left: -420%;
    width: 150px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
  }
}
@media screen and (min-width: 501px) {
  #dropdown {
    position: absolute;
    top: 100%;
    left: -397%;
    width: 150px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
  }
}

/* dropdown menu/dropdown menu items */
/*#dropdown {
  position: absolute;
  top: 100%;
  left: -385%;
  width: 150px;
  z-index: 2;
}*/
/* list in dropdown menu */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top:15px;
}
li {
  width:253%;
  font-weight:600;
  background-color:#6B705C;
  padding: 8px 12px;
  font-size: 20px;
  text-align:left;
}